import QuestionOrganism from 'questionFlow/QuestionOrganism';
import { useCallback, useMemo, useState } from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import {
  DefaultIncomeQuestionComponentProps,
  incomeConditionTypesEnum,
  StepNamesDefaultIncome,
} from 'questionFlow/flows/defaultIncome/types';

import { Button, Field, Input, OutlineNumberedBullet, TextButton } from 'components';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import TrueLayer from 'assets/images/TrueLayer.png';
import FCA from 'assets/images/FCA.png';
import HMRC from 'assets/images/hmrc.png';
import goToTruelayerAuthUrl, { composeUrlWithParams } from 'pages/OpenBanking/goToTruelayerAuthUrl';
import { useAnalyticsEvent } from 'monitoring/analyticsHooks';
import { IncomeSourceTypeEnum, WizardConditionType } from 'generated/graphql';

const cmsLocation = '/questions/income/openBanking';

const useContent = (t: TFunction<'translation'>) => ({
  title: t(`${cmsLocation}.title`),
  subtitle: t(`${cmsLocation}.subtitle`),
  description: t(`${cmsLocation}.description`),
  openBankingButton: t(`${cmsLocation}.Button`),
  hrmcButton: t(`${cmsLocation}.hrmcButton`),
  uploadDocumentsDescription: t(`${cmsLocation}.uploadDocumentsDescription`),
  uploadDocumentsDescriptionLink: t(`${cmsLocation}.uploadDocumentsDescriptionLink`),
  altTextTrueLayerLogo: t(`${cmsLocation}.altTextTrueLayerLogo`),
  altTextFCALogo: t(`${cmsLocation}.altTextFCALogo`),
  underBullets: t(`${cmsLocation}.underBullets`),
  bullet1: t(`${cmsLocation}.bullet1`),
  bullet2: t(`${cmsLocation}.bullet2`),
});

const stepName = StepNamesDefaultIncome.openBanking;

export const shouldRenderManualUpload = (conditions?: WizardConditionType[]) => {
  const manualUploadCondition = conditions?.find(
    cond => cond.name === incomeConditionTypesEnum.ADD_MANUAL_DOC_UPLOAD,
  );
  // @ts-ignore
  return manualUploadCondition ? manualUploadCondition.boolValue : true;
};

export const shouldRenderHmrc = (
  incomeType: IncomeSourceTypeEnum | undefined | null,
  conditions?: WizardConditionType[],
) => {
  if (!incomeType) return false;

  // @ts-ignore
  const allowedTypesList = conditions?.find(
    cond => cond.name === incomeConditionTypesEnum.HMRC_VERIFICATION_INCOME_TYPES,
  )?.listStrValues as Partial<IncomeSourceTypeEnum>[];

  return allowedTypesList?.includes(incomeType);
};

const goToHmrcAuthUrl = (uuid: string, nino: string) => {
  window.location.href = composeUrlWithParams(process.env.REACT_APP_HMRC_URI || '', {
    uuid: uuid,
    nino: nino,
  });
};

const OpenBanking = ({
  data,
  onSubmit,
  values: initialValues,
  validationRules: validationRulesGeneric,
  ...props
}: DefaultIncomeQuestionComponentProps) => {
  const { t } = useTranslation();
  const content = useContent(t);
  const { broadcastEvent } = useAnalyticsEvent();

  const handleSubmit = useCallback(() => {
    broadcastEvent('Clicked Manual Upload Button');
    onSubmit({ key: stepName, value: null });
  }, [onSubmit, broadcastEvent]);

  const subtitle = (
    <Trans
      i18nKey={`${cmsLocation}.subtitle`}
      components={{
        b: <b />,
      }}
    />
  );

  const onClickHandler = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    broadcastEvent('Clicked OB Button');
    goToTruelayerAuthUrl({ uuid: initialValues.uuid || '' });
  };

  const validateNiNumber = (number: string) => {
    const niRegex = /^[A-CEGHJ-PR-TW-Z]{2}[0-9]{6}[A-D]$/;
    if (!niRegex.test(number)) {
      return 'Invalid National Insurance number format.';
    }
    return '';
  };

  const onClickHmrcHandler = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!niNumber) {
      return;
    }
    const error = validateNiNumber(niNumber);
    if (error) {
      setValidationError(error);
      return;
    }
    goToHmrcAuthUrl(initialValues.uuid || '', niNumber);
  };

  const handleNiNumberChange = (e: any) => {
    const number = e.target.value.toUpperCase(); // Convert to uppercase for consistency
    setNiNumber(number);
  };
  const renderManualUpload = useMemo(() => shouldRenderManualUpload(data.conditions), [data]);
  const renderHmrcOption = useMemo(
    () => shouldRenderHmrc(initialValues?.incomeType, data.conditions),
    [initialValues?.incomeType, data],
  );

  const [hmrcIsVisible, setHmrcIsVisible] = useState<boolean>(false);
  const [niNumber, setNiNumber] = useState<string>('');
  const [validationError, setValidationError] = useState('');

  return (
    <QuestionOrganism
      id={stepName}
      data-testid={stepName}
      onSubmit={handleSubmit}
      title={content.title}
      subtitle={subtitle}
      isFooterHidden
      {...props}
    >
      {hmrcIsVisible ? (
        <div className="">
          <Field
            label={'National Insurance number'}
            validationError={validationError}
            data-testid={''}
          >
            <Input
              id={`national-insurance-number`}
              name={'national-insurance-number'}
              onChange={handleNiNumberChange}
              aria-invalid={!!validationError}
              placeholder="AB123456C"
            />
          </Field>
          <Button
            onClick={onClickHmrcHandler}
            className="flex items-center justify-center w-full md:w-auto"
          >
            {content.hrmcButton}
          </Button>
          <p className="lg:pt-4">
            You can find your National Insurance number on documents like a P60, payslips, or
            benefit letters, in your personal tax account, in the HMRC app, or in your Apple or
            Google Wallet if you've previously saved it there.
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-12">
          <div className="col-span-full md:col-span-8 flex flex-col space-y-6 lg:space-y-8 mb-8 lg:mb-12 mt-4">
            <OutlineNumberedBullet number="1" contentKey={`${cmsLocation}.bullet1`} />
            <OutlineNumberedBullet number="2" contentKey={`${cmsLocation}.bullet2`} />

            <p className="lg:pt-4">
              <Trans
                i18nKey={`${cmsLocation}.underBullets`}
                components={{
                  b: <b />,
                }}
              />
            </p>
          </div>

          <div className="w-full md:col-span-8">
            <div className="flex flex-col md:flex-row md:space-x-4 mt-3 mb-8">
              <Button
                onClick={onClickHandler}
                className="flex items-center justify-center w-full md:w-auto"
              >
                {content.openBankingButton}
              </Button>
              {renderHmrcOption && (
                <Button
                  onClick={() => {
                    setHmrcIsVisible(true);
                  }}
                  className="flex items-center justify-center w-full md:w-auto"
                >
                  {content.hrmcButton}
                </Button>
              )}
            </div>
            <div className="mb-12 opacity-40">
              <img
                src={TrueLayer}
                className="mr-10 inline-block"
                alt={content.altTextTrueLayerLogo}
              />
              <img src={FCA} className="w-100 inline-block mr-10" alt={content.altTextFCALogo} />
              {renderHmrcOption && <img src={HMRC} className="w-100 inline-block" alt="HMRC" />}
            </div>

            {renderManualUpload ? (
              <p className="flex border-t-2 pt-8" data-testid="manual-upload">
                <ExclamationCircleIcon className="w-5 h-5 mr-5 flex-shrink-0" />
                <span className="text-sm">
                  <div className="mb-6">
                    <Trans
                      i18nKey={`${cmsLocation}.uploadDocumentsDescription`}
                      components={{
                        b: <b />,
                      }}
                    />
                  </div>
                  <TextButton onClick={handleSubmit}>
                    {content.uploadDocumentsDescriptionLink}
                  </TextButton>
                </span>
              </p>
            ) : null}
          </div>
        </div>
      )}
    </QuestionOrganism>
  );
};

export default OpenBanking;
